import { StaticImage } from "gatsby-plugin-image"
import { ExternalButton } from "../ExternalButton"
import { useIntl } from "gatsby-plugin-react-intl";
import React from "react"
import {
  Container,
  Top,
  Thumbnail,
  Info,
  Images,
  ImageWrapper,
  Description,
} from "./style"

export const Astroflap = () => {
  const intl = useIntl();
  return (
    <Container>
      <Top>
        <Thumbnail>
          <StaticImage
            src="../../images/astroflap-thumbnail.jpg"
            alt="logo"
            placeholder="blurred"
            quality={100}
          />
        </Thumbnail>
        <Info>
          <h1>{intl.formatMessage({ id: "AstroflapTitle" })}</h1>
          <p>
            {intl.formatMessage({ id: "AstroflapSmallDescription" })}
          </p>
          <ExternalButton
            text="demo"
            href="https://oakgames.it/games/astroflap/"
          />
        </Info>
      </Top>
      <Images>
        <ImageWrapper>
          <StaticImage
            src="../../images/astroflap-screen-1.jpg"
            alt="logo"
            placeholder="blurred"
            quality={100}
            objectFit="cover"
            style={{ height: "100%" }}
          />
        </ImageWrapper>
        <ImageWrapper>
          <StaticImage
            src="../../images/astroflap-screen-2.jpg"
            alt="logo"
            placeholder="blurred"
            objectFit="cover"
            quality={100}
            style={{ height: "100%" }}
          />
        </ImageWrapper>
        <ImageWrapper>
          <StaticImage
            src="../../images/astroflap-screen-3.jpg"
            objectFit="cover"
            alt="logo"
            placeholder="blurred"
            quality={100}
            style={{ height: "100%" }}
          />
        </ImageWrapper>
      </Images>
      <Description>
        {intl.formatMessage({ id: "AstroflapBigDescription" })}
      </Description>
    </Container>
  )
}
