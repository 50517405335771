import * as React from "react"
import { Layout } from "../../components/Layout"
import { Astroflap } from "../../components/Astroflap"
import { Helmet } from "react-helmet"

const AstroFlapPage = () => {
  return (
    <Layout>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <meta charSet="utf-8" />
        <title>OakPong - Astroflap</title>
        <meta
          name="description"
          content="OakPong - new ideas for your company"
        />
      </Helmet>
      <Astroflap />
    </Layout>
  )
}

export default AstroFlapPage
