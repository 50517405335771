import styled from "styled-components"

export const Container = styled.div``

export const Top = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 50%);
  width: 100%;
  @media (max-width: 890px) {
    display: flex;
    flex-direction: column;
  }
`

export const Thumbnail = styled.div`
  width: 100%;
`

export const Description = styled.p`
  padding: 64px 48px;
  border: 1px solid #000;
  @media (max-width: 890px) {
    padding: 24px;
  }
`

export const Info = styled.div`
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 18px;
  border: 1px solid #000;
`

export const Images = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  width: 100%;
  flex-direction: row;
  height: 216px;
  @media (max-width: 890px) {
    display: flex;
    flex-direction: column;
    height: auto;
  }
`

export const ImageWrapper = styled.div`
  width: 100%;
`
